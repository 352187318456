import { useDispatch } from "react-redux";
import { Button, Col, InputNumber, Row, Select } from "antd";
import { useHistory } from "react-router-dom";
import { useTypedSelector } from "@hooks";
import {
  handleAgingDetails,
  handleStage,
  IAgingDetails,
  IPercentileDetails,
  IRegionCountryDetails,
  IRewardElementsDetails,
  ISectorAndIndustryDetails,
  PercentileDetailsReportByKeys,
  RegionCountryDetailsReportByKeys,
  ReportOn,
  resetDynamicReporting,
  RewardElementDetailsReportByKeys,
} from "@store/dynamicReporting";
import {
  DynamicReportingStages,
  IDynamicReportingStages,
  JobEvaluationStages,
} from "@types";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { ErrorServices, useCreateDynamicReportMutation } from "@/services";
import { showSuccessPopup } from "@/utils";
import { paths } from "@/router";

const { Option } = Select;

interface IFinalPayload {
  reportName?: string;
  regionCountryDetails: IRegionCountryDetails;
  sectorIndustryDetails: ISectorAndIndustryDetails;
  rewardElementsDetails: IRewardElementsDetails;
  percentileDetails: IPercentileDetails;
  agingDetails: IAgingDetails;
}

const AgingStep = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useTypedSelector(state => state.auth);

  const company_id = user?.company?.id || 0;

  const {
    regionCountryDetails,
    sectorIndustryDetails,
    rewardElementsDetails,
    percentileDetails,
    agingDetails,
    reportName,
    postLastPairGroup,
  } = useTypedSelector(state => state?.dynamicReporting);
  const {
    isAgingForMarketData,
    isApplyAgingForCompany,
    agingForMarketData,
    agingForCompanyData,
  } = agingDetails;

  const handleChange = (event: any) => {
    dispatch(
      handleAgingDetails({
        [event.target.name]: event.target.value,
      })
    );
  };

  const [createDynamicReport, { isLoading: isLoadingCreateJobEvaluation }] =
    useCreateDynamicReportMutation();

  const handleSubmit = async () => {
    let payloadObj = {
      regionCountryDetails,
      sectorIndustryDetails,
      rewardElementsDetails,
      percentileDetails,
      agingDetails,
      reportName,
    };
    const finalPayloadObj = reportGenerationPayloadMapper(payloadObj);

    try {
      const dynamicReportGenerationResp = await createDynamicReport({
        company_id,
        body: finalPayloadObj,
      }).unwrap();

      showSuccessPopup({
        title: "Report Generation Request Received Successfully",
        desc: dynamicReportGenerationResp.message,
      });

      dispatch(resetDynamicReporting());
      history.push(paths.client.dynamicReporting.listing());
    } catch (error) {
      ErrorServices(error);
      console.error({ error });
    }
  };

  const reportGenerationPayloadMapper = (payload: IFinalPayload) => {
    let report_by: RegionCountryDetailsReportByKeys[] = [];
    const reward_elements: RewardElementDetailsReportByKeys[] = [];
    let report_on: ReportOn[] = [];
    const percentile: number[] = [];

    // Map report_by from Redux Store
    payload.regionCountryDetails.isReportByGrade &&
      report_by.push(RegionCountryDetailsReportByKeys.ReportByGrade);
    payload.regionCountryDetails.isReportByTitle &&
      report_by.push(RegionCountryDetailsReportByKeys.ReportByTitle);

    payload.regionCountryDetails.isReportOnPayrollData &&
      report_on.push(ReportOn.ReportOnPayrollData);
    payload.regionCountryDetails.isReportOnTomData &&
      report_on.push(ReportOn.ReportOnTomData);

    // Map reward_elements from Redux Store
    payload.rewardElementsDetails.isRewardBy_basicPay &&
      reward_elements.push(RewardElementDetailsReportByKeys.ReportByBasicPay);
    payload.rewardElementsDetails.isRewardBy_guaranteeCash &&
      reward_elements.push(
        RewardElementDetailsReportByKeys.ReportByGarunteedCash
      );

    payload.rewardElementsDetails.isRewardBy_ttc_Acctual &&
      reward_elements.push(RewardElementDetailsReportByKeys.ReportByTccActual);
    payload.rewardElementsDetails.isRewardBy_tdc_Acctual &&
      reward_elements.push(RewardElementDetailsReportByKeys.ReportByTdcActual);
    payload.rewardElementsDetails.isRewardBy_tr_Acctual &&
      reward_elements.push(RewardElementDetailsReportByKeys.ReportByTrActual);

    payload.rewardElementsDetails.isRewardBy_ttc_Target &&
      reward_elements.push(RewardElementDetailsReportByKeys.ReportByTtcTarget);
    payload.rewardElementsDetails.isRewardBy_tdc_Target &&
      reward_elements.push(RewardElementDetailsReportByKeys.ReportByTdcTarget);
    payload.rewardElementsDetails.isRewardBy_tr_Target &&
      reward_elements.push(RewardElementDetailsReportByKeys.ReportByTrTarget);

    // Map percentile from Redux Store
    payload.percentileDetails.isCustom &&
      percentile.push(payload.percentileDetails.customPercentile!);
    payload.percentileDetails.isp10 &&
      percentile.push(PercentileDetailsReportByKeys.P10);
    payload.percentileDetails.isp25 &&
      percentile.push(PercentileDetailsReportByKeys.P25);
    payload.percentileDetails.isp50 &&
      percentile.push(PercentileDetailsReportByKeys.P50);
    payload.percentileDetails.isp75 &&
      percentile.push(PercentileDetailsReportByKeys.P75);
    payload.percentileDetails.isp90 &&
      percentile.push(PercentileDetailsReportByKeys.P90);

    let finalPayload: any = {};

    finalPayload.report_by = report_by;
    finalPayload.reward_elements = reward_elements;
    finalPayload.name = payload.reportName;
    finalPayload.report_on = [...report_on];
    finalPayload.from_date = payload.regionCountryDetails.reportFrom;
    finalPayload.to_date = payload.regionCountryDetails.reportTill;
    finalPayload.percentiles = percentile;

    payload.agingDetails.isApplyAgingForCompany &&
      (finalPayload.company_data_aging =
        payload.agingDetails.agingForCompanyData);
    payload.agingDetails.isAgingForMarketData &&
      (finalPayload.market_data_aging =
        payload.agingDetails.agingForMarketData);

    finalPayload.countries = payload.regionCountryDetails.countries;
    finalPayload.regions = payload.regionCountryDetails.regions;

    finalPayload.sectors = payload.sectorIndustryDetails.sectors;
    finalPayload.industries = payload.sectorIndustryDetails.industries;
    finalPayload.sub_industries = payload.sectorIndustryDetails.subIndustries;

    return finalPayload;
  };

  const disabled =
    (!isAgingForMarketData && !isApplyAgingForCompany) ||
    (isAgingForMarketData && !agingForMarketData) ||
    (isApplyAgingForCompany && !agingForCompanyData);

  return (
    <>
      <Row className="offers__form__header">
        <div className="sub-heading">Apply Aging</div>
      </Row>
      <div className="offers__form__fields">
        <Row justify="space-between" className="mb-32">
          <Col span={7}>
            <Checkbox
              checked={isAgingForMarketData}
              onChange={(e: any) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
                if (!e.target.checked) {
                  handleChange({
                    target: {
                      name: "agingForMarketData",
                      value: undefined,
                    },
                  });
                }
              }}
              className="mt-32"
              name="isAgingForMarketData"
            >
              Apply Aging for Market Data
            </Checkbox>
          </Col>
        </Row>
        {isAgingForMarketData && (
          <Row justify="space-between" className="modal__row">
            <Col span={15}>
              <label>Market Data Aging Value</label>
            </Col>
            <Col span={15}>
              <InputNumber
                size="large"
                min={0}
                max={100}
                value={agingForMarketData}
                onChange={(value: any) => {
                  handleChange({
                    target: {
                      name: "agingForMarketData",
                      value: value ? value : undefined,
                    },
                  });
                }}
              />{" "}
              %
            </Col>
          </Row>
        )}
        <Row>
          <Col span={7}>
            <Checkbox
              checked={isApplyAgingForCompany}
              onChange={(e: any) => {
                handleChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });

                if (!e.target.checked) {
                  handleChange({
                    target: {
                      name: "agingForCompanyData",
                      value: undefined,
                    },
                  });
                }
              }}
              className="mt-32"
              name="isApplyAgingForCompany"
            >
              Apply Aging for Company
            </Checkbox>
          </Col>
        </Row>
        {isApplyAgingForCompany && (
          <Row justify="space-between" className="modal__row">
            <Col span={15}>
              <label>Copmany Data Aging Value</label>
            </Col>
            <Col span={15}>
              <InputNumber
                size="large"
                min={0}
                max={100}
                value={agingForCompanyData}
                onChange={(value: any) => {
                  handleChange({
                    target: {
                      name: "agingForCompanyData",
                      value: value ? value : undefined,
                    },
                  });
                }}
              />{" "}
              %
            </Col>
          </Row>
        )}
      </div>
      <div className="offers__form__fields">
        <Row className="offers__form__fields__btns">
          <Button
            size="large"
            className="secondary-btn"
            onClick={() =>
              dispatch(handleStage(DynamicReportingStages.PERCENTILE))
            }
          >
            Back
          </Button>
          <Button
            disabled={disabled}
            onClick={() => handleSubmit()}
            size="large"
            className="secondary-btn"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              dispatch(resetDynamicReporting());
            }}
            size="large"
            className="secondary-btn"
          >
            Cancel
          </Button>
        </Row>
      </div>
    </>
  );
};

export default AgingStep;
