import { useState } from "react";
import { Col, Row, TableColumnsType, Tag, Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import "./addOffer.less";
import { paths } from "@router";

import { dateFormat_withTime, JobEvaluationInquiryStatus } from "@/constants";
import moment from "moment";
import { useTypedSelector } from "@/hooks";
import { handlePageChange, resetJobDetails } from "@/store/jobEvaluation";
import { useDispatch } from "react-redux";
import { useFetchCompanyQuery } from "@services";
import Modal from "@components/Modal";
import { DynamicReportingStages } from "@/types";
import RegionAndCountryStep from "./RegionAndCountryStep";
import SectorIndustryAndSubIndustryStep from "./SectorIndustryAndSubIndustryStep";
import RewardElementsStep from "./RewardElementsStep";
import PercentileStep from "./PercentileStep";
import AgingStep from "./AgingStep";
import { handleReportNameChange } from "@/store/dynamicReporting";

const components: any = {
  [DynamicReportingStages.REGION_COUNTRY]: <RegionAndCountryStep />,
  [DynamicReportingStages.SECTOR_INDUSTRY_AND_SUB_INDUSTRY]: (
    <SectorIndustryAndSubIndustryStep />
  ),
  [DynamicReportingStages.REWARD_ELEMENTS]: <RewardElementsStep />,
  [DynamicReportingStages.PERCENTILE]: <PercentileStep />,
  [DynamicReportingStages.AGING]: <AgingStep />,
};

const DynamicReporting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const { jobEvaluationListingPage } = useTypedSelector(
    state => state?.jobEvaluation
  );

  const { dynamicReportSteps, reportName } = useTypedSelector(
    state => state.dynamicReporting
  );
  const stage = dynamicReportSteps?.stage;

  const { user } = useTypedSelector(state => state.auth);
  const company_id = user?.company?.id || 0;
  const { data: companyData } = useFetchCompanyQuery({ company_id });
  const { data: company } = companyData || {};

  const [isAllowedReportCreation, setisAllowedReportCreation] = useState(false);
  const [reportNameForm, setReportNameForm] = useState("");

  const onCloseModal = () => {
    setIsVisible(false);
  };

  const handleTablePageChange = (newPage: number) => {
    dispatch(handlePageChange(newPage));
  };

  return (
    <>
      <Modal
        footer={[
          <Button
            key="1"
            type="primary"
            disabled={reportNameForm ? false : true}
            onClick={() => {
              reportNameForm &&
                dispatch(handleReportNameChange(reportNameForm));
              setIsVisible(false);
              setReportNameForm("");
            }}
          >
            {`Save`}
          </Button>,
          <Button
            key="2"
            onClick={() => {
              setIsVisible(false);
              setReportNameForm("");
            }}
          >
            Cancel
          </Button>,
        ]}
        title={`Create Report Name`}
        isVisible={isVisible}
      >
        <>
          <Row className="modal__row">
            <Col span={15}>
              <label>Report Name</label>
              <Input
                placeholder="Please name your report"
                size="large"
                name={"reportName"}
                onChange={e => setReportNameForm(e.target.value)}
                value={reportNameForm}
              />
            </Col>
          </Row>
        </>
      </Modal>
      <div className="container">
        <Row>
          <Col span={24}>
            <div className="main-heading mb-16">{`Dynamic Benchmark Reporting`}</div>
          </Col>
        </Row>
        <div className="addCompany">
          <Row className="addCompany__header">
            <div className="sub-heading">Company Details</div>
          </Row>
          <div className="addCompany__content">
            <div className="offers__form__fields">
              <Row className="companyDetails__header">
                <div className="companyDetails__header__leftCol">
                  <div className="companyDetails__header__leftCol__img">
                    {company?.large_logo_url && (
                      <img src={company?.large_logo_url} alt="Unilever Logo" />
                    )}
                  </div>
                  <div className="companyDetails__header__leftCol__info">
                    <div className="main-heading">{company?.name}</div>
                    <div className="main-heading">{company?.country?.name}</div>
                    <p>{company?.address} </p>
                  </div>
                </div>
                <Button
                  type="primary"
                  size="large"
                  disabled={reportName ? true : false}
                  onClick={() => {
                    setIsVisible(true);
                  }}
                >
                  Create Report
                </Button>
              </Row>
            </div>
          </div>
        </div>
      </div>
      {reportName && (
        <>
          <div className="container">
            <div className="addCompany">
              <Row className="addCompany__header">
                <div className="main-heading">{`${reportName}`}</div>
              </Row>
            </div>
          </div>

          <div className="offers">
            {/* <Row>
              <Col span={24}>
                <div className="main-heading">{`${reportName}`}</div>
              </Col>
            </Row> */}
            <Row className="mt-32 offers__steps">
              <div
                className={`offers__steps__step  offers__steps__step--active`}
              >
                <div className="offers__steps__step__circle">
                  <span className="offers__steps__step__circle__num">1</span>
                </div>
                <div className="offers__steps__step__title">
                  Region & Country Selection
                </div>
                <div
                  className={`offers__steps__step__border ${
                    stage &&
                    stage !== DynamicReportingStages.REGION_COUNTRY &&
                    "offers__steps__step__border--active"
                  }`}
                />
              </div>

              <div
                className={`offers__steps__step  ${
                  stage &&
                  stage !== DynamicReportingStages.REGION_COUNTRY &&
                  "offers__steps__step--active"
                }`}
              >
                <div className="offers__steps__step__circle">
                  <span className="offers__steps__step__circle__num">2</span>
                </div>
                <div className="offers__steps__step__title">
                  Sector & Industry Selection
                </div>
                <div
                  className={`offers__steps__step__border ${
                    stage &&
                    stage !== DynamicReportingStages.REGION_COUNTRY &&
                    stage !==
                      DynamicReportingStages.SECTOR_INDUSTRY_AND_SUB_INDUSTRY &&
                    "offers__steps__step__border--active"
                  }`}
                />
              </div>

              <div
                className={`offers__steps__step  ${
                  stage &&
                  stage !== DynamicReportingStages.REGION_COUNTRY &&
                  stage !==
                    DynamicReportingStages.SECTOR_INDUSTRY_AND_SUB_INDUSTRY &&
                  "offers__steps__step--active"
                }`}
              >
                <div className="offers__steps__step__circle">
                  <span className="offers__steps__step__circle__num">3</span>
                </div>
                <div className="offers__steps__step__title">
                  Reward Elements Selection
                </div>
                <div
                  className={`offers__steps__step__border ${
                    stage &&
                    stage !== DynamicReportingStages.REGION_COUNTRY &&
                    stage !==
                      DynamicReportingStages.SECTOR_INDUSTRY_AND_SUB_INDUSTRY &&
                    stage !== DynamicReportingStages.REWARD_ELEMENTS &&
                    "offers__steps__step__border--active"
                  }`}
                />
              </div>

              <div
                className={`offers__steps__step  ${
                  stage &&
                  stage !== DynamicReportingStages.REGION_COUNTRY &&
                  stage !==
                    DynamicReportingStages.SECTOR_INDUSTRY_AND_SUB_INDUSTRY &&
                  stage !== DynamicReportingStages.REWARD_ELEMENTS &&
                  "offers__steps__step--active"
                }`}
              >
                <div className="offers__steps__step__circle">
                  <span className="offers__steps__step__circle__num">4</span>
                </div>
                <div className="offers__steps__step__title">
                  Percentile Selection
                </div>
                <div
                  className={`offers__steps__step__border ${
                    stage &&
                    stage !== DynamicReportingStages.REGION_COUNTRY &&
                    stage !==
                      DynamicReportingStages.SECTOR_INDUSTRY_AND_SUB_INDUSTRY &&
                    stage !== DynamicReportingStages.REWARD_ELEMENTS &&
                    DynamicReportingStages.SECTOR_INDUSTRY_AND_SUB_INDUSTRY &&
                    stage !== DynamicReportingStages.PERCENTILE &&
                    "offers__steps__step__border--active"
                  }`}
                />
              </div>

              <div
                className={`offers__steps__step  ${
                  stage &&
                  stage !== DynamicReportingStages.REGION_COUNTRY &&
                  stage !==
                    DynamicReportingStages.SECTOR_INDUSTRY_AND_SUB_INDUSTRY &&
                  stage !== DynamicReportingStages.REWARD_ELEMENTS &&
                  DynamicReportingStages.SECTOR_INDUSTRY_AND_SUB_INDUSTRY &&
                  stage !== DynamicReportingStages.PERCENTILE &&
                  "offers__steps__step--active"
                }`}
              >
                <div className="offers__steps__step__circle">
                  <span className="offers__steps__step__circle__num">5</span>
                </div>
                <div className="offers__steps__step__title">
                  Aging Selection
                </div>
              </div>
            </Row>
            <div className="offers__form mt-32">
              {dynamicReportSteps ? (
                components[stage!]
              ) : (
                <RegionAndCountryStep />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DynamicReporting;
