import { useState } from "react";
import { Col, Row, TableColumnsType, Tag, Button } from "antd";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import "./DynamicReporting.less";
import { paths } from "@router";
import {
  useFetchAllDynamicReportsQuery,
  useFetchAllJobEvaluationsQuery,
} from "@services";
import Table from "@components/Table";
import { IJobEvaluationDataResponse } from "@/store/jobEvaluation/types";
import {
  dateFormat_onlyDate,
  dateFormat_withTime,
  JobEvaluationInquiryStatus,
} from "@/constants";
import moment from "moment";
import { useTypedSelector } from "@/hooks";
import { handlePageChange, resetJobDetails } from "@/store/jobEvaluation";
import { useDispatch } from "react-redux";

const DynamicReporting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const { dynamicReportsListingPage } = useTypedSelector(
    state => state?.dynamicReporting
  );

  const { user } = useTypedSelector(state => state.auth);
  const company_id = user?.company?.id || 0;

  const { data: dynamicReportsData, isLoading } =
    useFetchAllDynamicReportsQuery({
      company_id,
    });
  const { data, pagination } = dynamicReportsData || {};

  const columns: TableColumnsType<IJobEvaluationDataResponse> = [
    {
      title: "REPORT ID",
      key: "id",
      width: 100,
      render: (record: any) => <div className="text-wrap">{record.id}</div>,
    },
    {
      title: "CREATED ON",
      key: "created_at",
      width: 120,
      render: (record: any) => (
        <div className="text-wrap">{`${moment(record.created_at).format(
          dateFormat_withTime
        )}`}</div>
      ),
    },
    {
      title: "REPORT TITLE",
      key: "name",
      width: 250,
      render: (record: any) => <div className="text-wrap">{record.name}</div>,
    },
    {
      title: "REPORT FROM DATE",
      key: "from_date",
      width: 120,
      render: (record: any) => (
        <div className="text-wrap">{`${moment(record.from_date).format(
          dateFormat_onlyDate
        )}`}</div>
      ),
    },
    {
      title: "REPORT TILL DATE",
      key: "to_date",
      width: 120,
      render: (record: any) => (
        <div className="text-wrap">{`${moment(record.to_date).format(
          dateFormat_onlyDate
        )}`}</div>
      ),
    },
    {
      title: "REPORT STATUS",
      key: "stage",
      width: 300,
      align: "center",
      render: (record: any) => {
        const color: { [key: string]: string } = {
          completed: "green",
          processing: "gold",
          error: "red",
          failed: "red",
        };
        const key = record.stage?.key!;
        return (
          <>
            <Tag color={color[key.toLowerCase()]}>{key.toUpperCase()}</Tag>

            {key === "completed" && (
              <Button
                shape="round"
                icon={<DownloadOutlined />}
                size="small"
                onClick={() => window.open(`${record.report}`, "_blank")}
              >
                Download Report
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const handleTablePageChange = (newPage: number) => {
    dispatch(handlePageChange(newPage));
  };

  return (
    <div className="companies">
      <Row>
        <Col span={24}>
          <div className="main-heading mb-16">Dynamic Reports</div>
          <div className="companies__upper mb-20">
            <Button
              onClick={() => {
                history.push(paths.client.dynamicReporting.create());
              }}
              type="primary"
            >
              <PlusOutlined /> Generate Dynamic Report
            </Button>
          </div>
          <Table
            columns={columns}
            data={data}
            isLoading={isLoading}
            pagination={true}
            count={pagination?.count}
            onChangePage={handleTablePageChange}
            page={dynamicReportsListingPage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DynamicReporting;
