import { createApi } from "@reduxjs/toolkit/query/react";

import { tomService } from "./restService";

const description = null;

export const jobFunctionsApi = createApi({
  reducerPath: "jobFunctionsApi ",
  baseQuery: tomService({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  }),
  tagTypes: ["JobFunctions", "SubJobFunctions"],
  endpoints: builder => ({
    /**
     * @Services - Job Functions
     */
    fetchAllJobFunctions: builder.query({
      query: () => ({
        url: "/job-functions/all",
        method: "GET",
      }),
      providesTags: ["JobFunctions"],
    }),
    fetchJobFunctions: builder.query({
      query: ({ page = 1 }) => ({
        url: `/job-functions/?page=${page}`,
        method: "GET",
      }),
      providesTags: ["JobFunctions"],
    }),

    createJobFunction: builder.mutation({
      query: data => ({
        url: "/job-function/",
        method: "POST",
        body: { ...data, description },
      }),
      invalidatesTags: ["JobFunctions"],
    }),
    fetchJobFunctionById: builder.query({
      query: ({ job_id }) => ({
        url: `/job-function/${job_id}/`,
        method: "GET",
      }),
      providesTags: ["SubJobFunctions"],
    }),
    updateJobFunction: builder.mutation({
      query: ({ id, name }) => ({
        url: `/job-function/${id}/`,
        method: "PUT",
        body: { name, description },
      }),
      invalidatesTags: ["JobFunctions"],
    }),
    deleteJobFunction: builder.mutation({
      query: id => ({
        url: `/job-function/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["JobFunctions"],
    }),
    uploadJobFunctions: builder.mutation({
      query: body => ({
        url: "/job-function/upload/",
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["JobFunctions"],
    }),
    downloadJobFunctions: builder.query({
      query: () => ({
        url: "/job-function/download/",
        method: "GET",
      }),
    }),

    /**
     * @Services - Sub-Job Functions
     */

    createSubJobFunction: builder.mutation({
      query: body => ({
        url: "/job-sub-function/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SubJobFunctions"],
    }),

    updateSubJobFunction: builder.mutation({
      query: ({ id, name, job_function_id }) => ({
        url: `/job-sub-function/${id}/`,
        method: "PUT",
        body: { name, job_function_id, description },
      }),
      invalidatesTags: ["SubJobFunctions"],
    }),
    deleteSubJobFunction: builder.mutation({
      query: ({ id }) => ({
        url: `/job-sub-function/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["SubJobFunctions"],
    }),
  }),
});

export const {
  useFetchAllJobFunctionsQuery,
  useFetchJobFunctionsQuery,
  useCreateJobFunctionMutation,
  useFetchJobFunctionByIdQuery,
  useUpdateJobFunctionMutation,
  useDeleteJobFunctionMutation,
  useUploadJobFunctionsMutation,
  useDownloadJobFunctionsQuery,

  useCreateSubJobFunctionMutation,
  useUpdateSubJobFunctionMutation,
  useDeleteSubJobFunctionMutation,
} = jobFunctionsApi;
