import { useDispatch } from "react-redux";
import { Button, Col, Row, Select } from "antd";
import { useHistory } from "react-router-dom";
import { useTypedSelector } from "@hooks";
import {
  handleSectorIndustryDetails,
  handleStage,
  resetDynamicReporting,
} from "@store/dynamicReporting";
import {
  DynamicReportingStages,
  ISubJobFunctionItem,
  JobEvaluationStages,
} from "@types";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {
  ErrorServices,
  useCheckPairGroupMutation,
  useFetchAllSectorsQuery,
} from "@/services";
import { useEffect, useState } from "react";

export interface ISectorIndustrySubIndustry {
  id: number;
  name: string;
  industries: IIndustrySubIndustry[];
}

export interface IIndustrySubIndustry {
  id: number;
  name: string;
  sub_industries: ISubJobFunctionItem[];
}

const { Option } = Select;

const SectorIndustryAndSubIndustryStep = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sectorIndustryDetails, regionCountryDetails } = useTypedSelector(
    state => state?.dynamicReporting
  );
  const {
    sectors,
    isAllSectorsSelected,
    industries,
    isAllIndustriesSelected,
    subIndustries,
    isAllSubIndustriesSelected,
  } = sectorIndustryDetails;
  const { regions, countries } = regionCountryDetails;

  const { data: sectorsData, isLoading: isLoadingSectors } =
    useFetchAllSectorsQuery(null);

  const { data: sectorsFetchedData } = sectorsData || { data: [] };

  const [CheckPairGroup] = useCheckPairGroupMutation();

  const [industries_BySectorsData, set_industries_BySectorsData] = useState<
    ISubJobFunctionItem[]
  >([]);

  const { user } = useTypedSelector(state => state.auth);

  const company_id = user?.company?.id || 0;

  const [
    subIndustries_Byindustries_BySectorsData,
    set_subIndustries_Byindustries_BySectorsData,
  ] = useState<ISubJobFunctionItem[]>([]);

  const { dynamicReportSteps } = useTypedSelector(
    state => state?.dynamicReporting
  );
  const handleSubmit = async () => {
    const PairGroup = {
      regions,
      countries,

      sectors,
      industries,
      sub_industries: subIndustries,
    };

    try {
      await CheckPairGroup({
        company_id,
        body: PairGroup,
      }).unwrap();

      if (dynamicReportSteps) {
        return dispatch(handleStage(DynamicReportingStages.REWARD_ELEMENTS));
      }
    } catch (error) {
      ErrorServices(error);
      console.error({ error });
    }
  };

  const handleChange = (event: any) => {
    dispatch(
      handleSectorIndustryDetails({
        [event.target.name]: event.target.value,
      })
    );
  };

  function handleIndustriesImpactOnSubIndustries(
    selectedIndustriesIdsArr: number[]
  ): void {
    let listOfSubIndustries: ISubJobFunctionItem[] = [];
    //set Sub-industries list as per sectors
    set_subIndustries_Byindustries_BySectorsData([]);
    sectorsFetchedData.forEach(
      (eachSectorDetails: ISectorIndustrySubIndustry) => {
        eachSectorDetails.industries.forEach(
          (eachIndustryDetails: IIndustrySubIndustry) => {
            if (selectedIndustriesIdsArr.includes(eachIndustryDetails.id)) {
              listOfSubIndustries = [
                ...listOfSubIndustries,
                ...eachIndustryDetails.sub_industries,
              ];
              set_subIndustries_Byindustries_BySectorsData(listOfSubIndustries);
            }
          }
        );
      }
    );

    //set selected countries as per regions
    let finalSelected_SubIndustries = subIndustries.filter(
      (eachSelectedSubIndustryId: number) => {
        return listOfSubIndustries.length
          ? listOfSubIndustries.find(x => x.id === eachSelectedSubIndustryId)
          : false;
      }
    );

    handleChange({
      target: {
        name: "subIndustries",
        value: finalSelected_SubIndustries,
      },
    });
  }
  function handleSectorsImpactOnIndustries(selectedSectors: number[]) {
    let listOfIndustries: ISubJobFunctionItem[] = [];

    //set industries list as per sectors
    set_industries_BySectorsData([]);
    sectorsFetchedData.forEach(
      (eachSectorDetails: { name: string; id: number; industries: [] }) => {
        if (selectedSectors.includes(eachSectorDetails.id)) {
          listOfIndustries = [
            ...listOfIndustries,
            ...eachSectorDetails.industries,
          ];
          set_industries_BySectorsData(listOfIndustries);
        }
      }
    );

    //set selected countries as per regions
    let finalSelectedIndustries = industries.filter(
      (eachSelectedIndustryId: number) => {
        return listOfIndustries.length
          ? listOfIndustries.find(x => x.id === eachSelectedIndustryId)
          : false;
      }
    );

    handleChange({
      target: {
        name: "industries",
        value: finalSelectedIndustries,
      },
    });
  }

  useEffect(() => {
    if (!isLoadingSectors) {
      handleSectorsImpactOnIndustries(sectors);
    }
  }, [sectors, isLoadingSectors]);
  useEffect(() => {
    if (!isLoadingSectors) {
      handleIndustriesImpactOnSubIndustries(industries);
    }
  }, [industries, isLoadingSectors]);

  // useEffect(() => {
  //   if (isAllSectorsSelected) {
  //     const allSectors = sectorsFetchedData.map(
  //       ({ name, id }: { name: string; id: number }) => id
  //     );

  //     handleChange({
  //       target: {
  //         name: "sectors",
  //         value: allSectors,
  //       },
  //     });
  //   }
  //   if (!isAllSectorsSelected) {
  //     handleChange({
  //       target: {
  //         name: "sectors",
  //         value: [],
  //       },
  //     });
  //   }

  //   if (isAllIndustriesSelected) {
  //     const allIndustries = industries_BySectorsData.map(
  //       ({ name, id }: { name: string; id: number }) => id
  //     );

  //     handleChange({
  //       target: {
  //         name: "industries",
  //         value: allIndustries,
  //       },
  //     });
  //   }
  //   if (!isAllIndustriesSelected) {
  //     handleChange({
  //       target: {
  //         name: "industries",
  //         value: [],
  //       },
  //     });
  //   }

  //   if (isAllSubIndustriesSelected) {
  //     const allSubIndustries = subIndustries_Byindustries_BySectorsData.map(
  //       ({ name, id }: { name: string; id: number }) => id
  //     );

  //     handleChange({
  //       target: {
  //         name: "subIndustries",
  //         value: allSubIndustries,
  //       },
  //     });
  //   }
  //   if (!isAllSubIndustriesSelected) {
  //     handleChange({
  //       target: {
  //         name: "subIndustries",
  //         value: [],
  //       },
  //     });
  //   }
  // }, [
  //   isAllSectorsSelected,
  //   isAllIndustriesSelected,
  //   isAllSubIndustriesSelected,
  // ]);

  const disabled =
    !sectors.length || !industries.length || !subIndustries.length;

  return (
    <>
      <Row className="offers__form__header">
        <div className="sub-heading">Choose Sector & Industry Details</div>
      </Row>
      <div className="offers__form__fields">
        <Row justify="space-between" className="mb-32">
          <Col span={9}>
            <label>Sectors</label>
            <Select
              loading={isLoadingSectors}
              disabled={isAllSectorsSelected}
              showArrow={true}
              size="large"
              showSearch={true}
              placeholder="Select sectors from here..."
              mode="multiple"
              value={sectors}
              maxTagCount="responsive"
              onChange={(receivedDataOnChange: number[]) => {
                handleChange({
                  target: {
                    name: "sectors",
                    value: receivedDataOnChange,
                  },
                });
              }}
            >
              {sectorsFetchedData?.map(
                ({ name, id }: { name: string; id: number }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                )
              )}
            </Select>
            {sectorsFetchedData.length > 0 && (
              <Checkbox
                checked={isAllSectorsSelected}
                onChange={(e: any) => {
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: e.target.checked,
                    },
                  });

                  if (e.target.checked) {
                    const allSectors = sectorsFetchedData.map(
                      ({ name, id }: { name: string; id: number }) => id
                    );

                    handleChange({
                      target: {
                        name: "sectors",
                        value: allSectors,
                      },
                    });
                  }
                  if (!e.target.checked) {
                    handleChange({
                      target: {
                        name: "sectors",
                        value: [],
                      },
                    });
                  }
                }}
                className="mt-32"
                name="isAllSectorsSelected"
              >
                Select All Sectors
              </Checkbox>
            )}
          </Col>
        </Row>
        <Row justify="space-between" className="mb-32">
          <Col span={9}>
            <label>Industries</label>
            <Select
              loading={isLoadingSectors}
              disabled={isAllIndustriesSelected}
              showArrow={true}
              size="large"
              showSearch={true}
              placeholder="Select industries from here..."
              mode="multiple"
              maxTagCount="responsive"
              value={industries}
              onChange={(receivedDataOnChange: number[]) => {
                handleChange({
                  target: {
                    name: "industries",
                    value: receivedDataOnChange,
                  },
                });
              }}
            >
              {industries_BySectorsData?.map(
                ({ name, id }: { name: string; id: number }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                )
              )}
            </Select>
            {industries_BySectorsData.length > 0 && (
              <Checkbox
                checked={isAllIndustriesSelected}
                onChange={(e: any) => {
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: e.target.checked,
                    },
                  });

                  if (e.target.checked) {
                    const allIndustries = industries_BySectorsData.map(
                      ({ name, id }: { name: string; id: number }) => id
                    );

                    handleChange({
                      target: {
                        name: "industries",
                        value: allIndustries,
                      },
                    });
                  }
                  if (!e.target.checked) {
                    handleChange({
                      target: {
                        name: "industries",
                        value: [],
                      },
                    });
                  }
                }}
                className="mt-32"
                name="isAllIndustriesSelected"
              >
                Select All Industries
              </Checkbox>
            )}
          </Col>
          <Col span={9}>
            <label>Sub-Industries</label>
            <Select
              loading={isLoadingSectors}
              disabled={isAllSubIndustriesSelected}
              showArrow={true}
              size="large"
              showSearch={true}
              placeholder="Select sub-industries from here..."
              mode="multiple"
              maxTagCount="responsive"
              value={subIndustries}
              onChange={(receivedDataOnChange: number[]) => {
                handleChange({
                  target: {
                    name: "subIndustries",
                    value: receivedDataOnChange,
                  },
                });
              }}
            >
              {subIndustries_Byindustries_BySectorsData?.map(
                ({ name, id }: { name: string; id: number }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                )
              )}
            </Select>
            {subIndustries_Byindustries_BySectorsData.length > 0 && (
              <Checkbox
                checked={isAllSubIndustriesSelected}
                onChange={(e: any) => {
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: e.target.checked,
                    },
                  });
                  if (e.target.checked) {
                    const allSubIndustries =
                      subIndustries_Byindustries_BySectorsData.map(
                        ({ name, id }: { name: string; id: number }) => id
                      );

                    handleChange({
                      target: {
                        name: "subIndustries",
                        value: allSubIndustries,
                      },
                    });
                  }
                  if (!e.target.checked) {
                    handleChange({
                      target: {
                        name: "subIndustries",
                        value: [],
                      },
                    });
                  }
                }}
                className="mt-32"
                name="isAllSubIndustriesSelected"
              >
                Select All Sub-Industries
              </Checkbox>
            )}
          </Col>
        </Row>
      </div>
      <div className="offers__form__fields">
        <Row className="offers__form__fields__btns">
          <Button
            size="large"
            className="secondary-btn"
            onClick={() =>
              dispatch(handleStage(DynamicReportingStages.REGION_COUNTRY))
            }
          >
            Back
          </Button>
          <Button
            disabled={disabled}
            onClick={() => handleSubmit()}
            size="large"
            className="secondary-btn"
          >
            Next
          </Button>
          <Button
            onClick={() => {
              dispatch(resetDynamicReporting());
            }}
            size="large"
            className="secondary-btn"
          >
            Cancel
          </Button>
        </Row>
      </div>
    </>
  );
};

export default SectorIndustryAndSubIndustryStep;
