import { createApi } from "@reduxjs/toolkit/query/react";

import { tomService } from "./restService";

export const marketDataApi = createApi({
  reducerPath: "marketDataApi",
  baseQuery: tomService({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/company`,
  }),
  tagTypes: ["MarketData", "MarketDataVersions"],
  endpoints: builder => ({
    updateMarketDataVersion: builder.mutation({
      query: ({ id, company_id }) => ({
        url: `/${company_id}/market-data-version/${id}/set-active/`,
        method: "PATCH",
      }),
      invalidatesTags: ["MarketData", "MarketDataVersions"],
    }),
    fetchMarketDataVersions: builder.query({
      query: ({ company_id, page = 1 }) => ({
        url: `/${company_id}/market-data-versions/?page=${page}`,
        method: "GET",
      }),
      providesTags: ["MarketDataVersions"],
    }),
    uploadMarketData: builder.mutation({
      query: ({ company_id, active, body }) => ({
        url: `/${company_id}/market-data/upload/?active=${active}`,
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["MarketData", "MarketDataVersions"],
    }),
    downloadMarketData: builder.query({
      query: ({ company_id, version_id }) => ({
        url: `/${company_id}/market-data/download/${
          version_id ? `?version_id=${version_id}` : ""
        }`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useDownloadMarketDataQuery,
  useFetchMarketDataVersionsQuery,
  useUpdateMarketDataVersionMutation,
  useUploadMarketDataMutation,
} = marketDataApi;
