import {
  DynamicReportingStages,
  IDynamicReportingStages,
  IJobEvaluationStages,
  JobEvaluationStages,
} from "@types";
import { createSlice } from "@reduxjs/toolkit";
import { jobEvaluationApi } from "@/services";
import { IDropDownNullOrString, IJobEvaluationDataResponse } from "./types";
import { evaluationModelOptions } from "@/constants";

type NameAndID = null | {
  name: string;
  id: number;
};

export type KeyAndDisplayName = null | {
  display_name: string;
  key: string;
};

export interface IRegionCountryDetails {
  regions: [];
  isAllRegionsSelected: boolean;
  countries: [];
  isAllCountriesSelected: boolean;
  isReportByGrade: boolean;
  isReportByTitle: boolean;
  isReportOnTomData: boolean;
  isReportOnPayrollData: boolean;
  reportFrom: string;
  reportTill: string;
}
export interface ISectorAndIndustryDetails {
  sectors: [];
  isAllSectorsSelected: boolean;
  industries: [];
  isAllIndustriesSelected: boolean;
  subIndustries: [];
  isAllSubIndustriesSelected: boolean;
}

export interface IPercentileDetails {
  isp10?: boolean;
  isp25?: boolean;
  isp50?: boolean;
  isp75?: boolean;
  isp90?: boolean;
  isCustom: boolean;
  customPercentile?: number;
}

export interface IAgingDetails {
  isAgingForMarketData: boolean;
  agingForMarketData?: number;
  isApplyAgingForCompany: boolean;
  agingForCompanyData?: number;
}

export interface IRewardElementsDetails {
  isRewardBy_basicPay: boolean;
  isRewardBy_guaranteeCash: boolean;
  isRewardBy_ttc_Acctual: boolean;
  isRewardBy_tdc_Acctual: boolean;
  isRewardBy_tr_Acctual: boolean;
  isRewardBy_ttc_Target: boolean;
  isRewardBy_tdc_Target: boolean;
  isRewardBy_tr_Target: boolean;
}

interface IInitialState {
  id: number | undefined;
  reportName?: string;
  regionCountryDetails: IRegionCountryDetails;
  sectorIndustryDetails: ISectorAndIndustryDetails;
  rewardElementsDetails: IRewardElementsDetails;
  percentileDetails: IPercentileDetails;
  agingDetails: IAgingDetails;
  dynamicReportSteps: Partial<IDynamicReportingStages> | null;
  postLastPairGroup: boolean;
  dynamicReportsListingPage: number;
}

export enum ReportOn {
  ReportOnPayrollData = "payroll_data",
  ReportOnTomData = "tom_data",
}
export enum RegionCountryDetailsReportByKeys {
  ReportByGrade = "grade",
  ReportByTitle = "title",
}
export enum RewardElementDetailsReportByKeys {
  ReportByBasicPay = "basic_pay",
  ReportByGarunteedCash = "garunteed_cash",
  ReportByTccActual = "tcc_actual",
  ReportByTdcActual = "tdc_actual",
  ReportByTrActual = "tr_actual",
  ReportByTtcTarget = "ttc_target",
  ReportByTdcTarget = "tdc_target",
  ReportByTrTarget = "tr_target",
}
export enum PercentileDetailsReportByKeys {
  P10 = 10,
  P25 = 25,
  P50 = 50,
  P75 = 75,
  P90 = 90,
}

const initialState: IInitialState = {
  id: undefined,
  reportName: undefined,
  regionCountryDetails: {
    countries: [],
    isAllCountriesSelected: false,
    regions: [],
    isAllRegionsSelected: false,
    isReportByGrade: false,
    isReportByTitle: false,
    isReportOnTomData: false,
    isReportOnPayrollData: false,

    reportFrom: "",
    reportTill: "",
  },
  sectorIndustryDetails: {
    sectors: [],
    isAllSectorsSelected: false,
    industries: [],
    isAllIndustriesSelected: false,
    subIndustries: [],
    isAllSubIndustriesSelected: false,
  },
  rewardElementsDetails: {
    isRewardBy_basicPay: false,
    isRewardBy_guaranteeCash: false,
    isRewardBy_ttc_Acctual: false,
    isRewardBy_tdc_Acctual: false,
    isRewardBy_tr_Acctual: false,
    isRewardBy_ttc_Target: false,
    isRewardBy_tdc_Target: false,
    isRewardBy_tr_Target: false,
  },

  percentileDetails: {
    isp10: false,
    isp25: false,
    isp50: false,
    isp75: false,
    isp90: false,
    isCustom: false,
    customPercentile: undefined,
  },
  agingDetails: {
    isAgingForMarketData: false,
    agingForMarketData: undefined,
    isApplyAgingForCompany: false,
    agingForCompanyData: undefined,
  },

  dynamicReportSteps: {
    stage: DynamicReportingStages.REGION_COUNTRY,
    is_active: true,
  },
  postLastPairGroup: true,
  dynamicReportsListingPage: 1,
};

const dynamicReportingSlice = createSlice({
  name: "dynamicReport",
  initialState,
  reducers: {
    handleStage: (state, action: { payload: DynamicReportingStages }) => {
      state.dynamicReportSteps = {
        ...state?.dynamicReportSteps,
        stage: action.payload,
      };
    },

    resetDynamicReporting: () => initialState,
    handleReportNameChange: (state, action: { payload: string }) => {
      state.reportName = action.payload;
    },
    handleRegionCountryDetails: (state, action) => {
      state.regionCountryDetails = {
        ...state?.regionCountryDetails,
        ...action.payload,
      };
    },
    handleSectorIndustryDetails: (state, action) => {
      state.sectorIndustryDetails = {
        ...state?.sectorIndustryDetails,
        ...action.payload,
      };
    },
    handleRewardElementsDetails: (state, action) => {
      state.rewardElementsDetails = {
        ...state?.rewardElementsDetails,
        ...action.payload,
      };
    },
    handlePercentileDetails: (state, action) => {
      state.percentileDetails = {
        ...state?.percentileDetails,
        ...action.payload,
      };
    },
    handleAgingDetails: (state, action) => {
      state.agingDetails = {
        ...state?.agingDetails,
        ...action.payload,
      };
    },
  },
});

export const {
  handleStage,
  handleReportNameChange,
  handleRegionCountryDetails,
  handleSectorIndustryDetails,
  handleRewardElementsDetails,
  handlePercentileDetails,
  handleAgingDetails,
  resetDynamicReporting,
} = dynamicReportingSlice.actions;

export default dynamicReportingSlice.reducer;
