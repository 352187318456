import { createApi } from "@reduxjs/toolkit/query/react";

import { tomService } from "./restService";

export const longTermIPApi = createApi({
  reducerPath: "longTermIPApi",
  baseQuery: tomService({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  }),
  tagTypes: [
    "LongTermPlans",
    "LongTermIncentives",
    "LongTermIncentiveVersions",
  ],

  // Long Term Plans
  endpoints: builder => ({
    fetchLongTermPlanTypes: builder.query({
      query: () => ({
        url: `/long-term-incentive-plan-types/`,
        method: "GET",
      }),
    }),
    fetchCurrencies: builder.query({
      query: () => ({
        url: `/currencies/`,
        method: "GET",
      }),
    }),
    fetchLongTermIncentiveVersions: builder.query({
      query: ({ company_id, page = 1 }) => ({
        url: `/company/${company_id}/long-term-incentive-versions/?page=${page}`,
        method: "GET",
      }),
      providesTags: ["LongTermIncentiveVersions"],
    }),
    fetchLongTermPlans: builder.query({
      query: ({ company_id, page = 1 }) => ({
        url: `/company/${company_id}/long-term-incentive-plans/?page=${page}`,
        method: "GET",
      }),
      providesTags: ["LongTermPlans"],
    }),
    fetchAllLongTermPlans: builder.query({
      query: ({ company_id, page = 1 }) => ({
        url: `/company/${company_id}/long-term-incentive-plans/all`,
        method: "GET",
      }),
      providesTags: ["LongTermPlans"],
    }),
    createLongTermPlan: builder.mutation({
      query: ({ company_id, body }) => ({
        url: `/company/${company_id}/long-term-incentive-plan/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["LongTermPlans"],
    }),
    updateLongTermPlan: builder.mutation({
      query: ({ company_id, body, id }) => ({
        url: `/company/${company_id}/long-term-incentive-plan/${id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["LongTermPlans"],
    }),
    deleteLongTermPlan: builder.mutation({
      query: ({ company_id, id }) => ({
        url: `/company/${company_id}/long-term-incentive-plan/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["LongTermPlans"],
    }),

    // Long Term Incentives
    fetchLongTermIncentives: builder.query({
      query: ({ company_id, page = 1 }) => ({
        url: `/company/${company_id}/long-term-incentives/?page=${page}`,
        method: "GET",
      }),
      providesTags: ["LongTermIncentives"],
    }),
    fetchLongTermIncentivesByPlan: builder.query({
      query: ({ company_id, plan_id, page = 1 }) => ({
        url: `/company/${company_id}/long-term-incentive-plan/${plan_id}/long-term-incentives/?page=${page}`,
        method: "GET",
      }),
      providesTags: ["LongTermIncentives"],
    }),
    createLongTermIncentive: builder.mutation({
      query: ({ company_id, body }) => ({
        url: `/company/${company_id}/long-term-incentive/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["LongTermIncentives"],
    }),
    updateLongTermIncentive: builder.mutation({
      query: ({ company_id, body, id }) => ({
        url: `/company/${company_id}/long-term-incentive/${id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["LongTermIncentives"],
    }),
    deleteLongTermIncentive: builder.mutation({
      query: ({ company_id, id }) => ({
        url: `/company/${company_id}/long-term-incentive/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["LongTermIncentives"],
    }),
    updateLongTermIncentiveVersion: builder.mutation({
      query: ({ id, company_id }) => ({
        url: `/company/${company_id}/long-term-incentive-version/${id}/set-active/`,
        method: "PATCH",
      }),
      invalidatesTags: ["LongTermIncentives", "LongTermIncentiveVersions"],
    }),
    downloadLongTermIncentives: builder.query({
      query: ({ company_id, version_id }) => ({
        url: `/company/${company_id}/long-term-incentive/download/${
          version_id ? `?version_id=${version_id}` : ""
        }`,
        method: "GET",
      }),
    }),
    uploadLongTermIncentives: builder.mutation({
      query: ({ company_id, active, body }) => ({
        url: `/company/${company_id}/long-term-incentive/upload/?active=${active}`,
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["LongTermIncentives", "LongTermIncentiveVersions"],
    }),
  }),
});

export const {
  useFetchAllLongTermPlansQuery,
  useFetchCurrenciesQuery,
  useFetchLongTermPlanTypesQuery,
  useFetchLongTermPlansQuery,
  useDeleteLongTermPlanMutation,
  useCreateLongTermPlanMutation,
  useUpdateLongTermPlanMutation,

  useFetchLongTermIncentivesByPlanQuery,
  useFetchLongTermIncentivesQuery,
  useCreateLongTermIncentiveMutation,
  useDeleteLongTermIncentiveMutation,
  useUpdateLongTermIncentiveMutation,
  useFetchLongTermIncentiveVersionsQuery,
  useUpdateLongTermIncentiveVersionMutation,
  useDownloadLongTermIncentivesQuery,
  useUploadLongTermIncentivesMutation,
} = longTermIPApi;
