export const roles = {
  admin: ["TOM_MASTER_USER", "TOM_SUPER_USER", "TOM_ADMIN", "TOM_SALES"],
  company: [
    "COMPANY_SUPER_USER", // apni compani
    "COMPANY_ADMIN",
    "COMPANY_USER",
    "COMPANY_BUSINESS_ACCESS",
  ],
};

export enum UserRoles {
  COMPANY_SUPER_USER = "COMPANY_SUPER_USER",
  COMPANY_ADMIN = "COMPANY_ADMIN",
  COMPANY_USER = "COMPANY_USER",
  COMPANY_BUSINESS_ACCESS = "COMPANY_BUSINESS_ACCESS",
  TOM_MASTER_USER = "TOM_MASTER_USER",
}

export interface IRoleManement {
  id: UserRoles;
  applicationCheck: boolean;
  permissionsCheck: boolean;
}
