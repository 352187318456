import { ReactComponent as UsersIcon } from "@assets/images/users.svg";
import { ReactComponent as CompaniesIcon } from "@assets/images/companies.svg";
import { paths, permissions } from "@router";
import { IJobGrade } from "@/types";
import { store } from "@store";

const { client } = paths;

export interface IConfig {
  title: string;
  icon: any;
  path: string | Array<string>;
  routingPath?: string;
  sub?: Array<IConfig>;
  permission?: string;
  client?: boolean;
  disabled?: boolean;
  fallbackMessage?: string;
}

export const admin_config: Array<IConfig> = [];

export const client_config = (jobGrades: IJobGrade[]): Array<IConfig> => [
  {
    title: "Live Pay Tool",
    icon: UsersIcon,
    client: true,
    path: [client.dynamicReporting.listing(), client.dynamicReporting.create()],
    sub: [
      {
        title: "Dynamic Reports",
        icon: CompaniesIcon,
        path: client.dynamicReporting.listing(),
        permission: permissions.VIEW_JOB_EVALUATION,
      },
    ],
  },
];
