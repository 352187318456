import { createApi } from "@reduxjs/toolkit/query/react";

import { tomService } from "./restService";

export const companySubAdmins = createApi({
  reducerPath: "companySubAdmins",
  baseQuery: tomService({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/company`,
  }),
  tagTypes: ["Users"],

  endpoints: builder => ({
    fetchCompanyUsers: builder.query({
      query: ({ company_id, page }) => ({
        url: `/${company_id}/users/?page=${page}`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),

    updateCompanyUserStatus: builder.mutation({
      query: ({ company_id, user_id, is_active }) => ({
        url: `/${company_id}/user/${user_id}/?active=${is_active}`,
        method: "PATCH",
      }),
      invalidatesTags: ["Users"],
    }),

    createCompanyUser: builder.mutation({
      query: ({ company_id, body }) => ({
        url: `/${company_id}/user/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    updateCompanyUser: builder.mutation({
      query: ({ company_id, body, id }) => ({
        url: `/${company_id}/user/${id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    fetchCompanyUserById: builder.query({
      query: ({ company_id, id }) => ({
        url: `/${company_id}/user/${id}/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useFetchCompanyUsersQuery,
  useCreateCompanyUserMutation,
  useUpdateCompanyUserStatusMutation,
  useFetchCompanyUserByIdQuery,
  useUpdateCompanyUserMutation,
} = companySubAdmins;
